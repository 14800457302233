/*eslint-disable*/
import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import FacebookSVG from "assets/img/klaxonImages/facebookIconB.svg";
import InstaSVG from "assets/img/klaxonImages/instagramIconB.svg";
import XSVG from "assets/img/klaxonImages/xIconB.svg";
import TiktokSVG from "assets/img/klaxonImages/tiktokB.svg";
import LinkedinSVG from "assets/img/klaxonImages/linkedinB.svg";

import klaxonLogo from "assets/img/klaxonImages/KlaxonLogoBlack.png";

import {useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react";

function FooterLight() {

    const location = useLocation()
    const navigation = useNavigate()

    const handleNavigateHome = () => {
        if (location.pathname !== "/voices") {
            navigation("/home");
        } else {
            window.location.href = "https://www.klaxon.ai/waitlist"
        }
    };

    const handleNavigateLegal = (page) => {
        navigation(`/${page}`)
    }

    const [extraPadding, setExtraPadding] = useState(false)
    const [isAudioHidden, setIsAudioHidden] = useState(false)
    useEffect(() => {
        if (location.pathname.startsWith("/company") && !isAudioHidden) {
            setExtraPadding(true)
        } else {
            setExtraPadding(false)
        }
    }, [location.pathname, isAudioHidden])

    useEffect(() => {
        const handleAudioVisibility = (event) => {
            const {mode } = event.detail
            setIsAudioHidden(mode)
        };

        // Add the event listener
        window.addEventListener("playerToggled", handleAudioVisibility);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("playerToggled", handleAudioVisibility);
        };
    }, []);
    

  return (
      <footer style={{ justifyContent: "center", backgroundColor: "#F8F8F8", borderTop: "3px solid #F33B30", paddingTop: "4%", marginBottom: extraPadding && 100 }}>
          <Col style={{ color: "white" }}>
              <Row className="justify-content-between footerContainer" style={{  borderTop: "1px solid rgba(255, 255, 255, 0.3)" }}>
                  <Col sm="12" md="12" lg="2" className="pb-4">
                      <Row className="mb-4">
                          <img src={klaxonLogo} style={{ height: "auto", width: 'auto', cursor: "pointer" }} onClick={handleNavigateHome} />
                      </Row>
                      <Row><p style={{ width: "100%", fontSize: 22, color: "black", fontWeight: "bold" }}>Podcasts without the faff.</p></Row>
                  </Col>

                  <Col sm="6" style={{ borderTop: "2px solid red", borderLeft: "2px solid red", borderTopLeftRadius: 35, marginLeft: "auto" }} className="pt-4 pl-4">
                      <Row>
                          <Col>
                              <Row><p style={{ color: "#F33B30", fontWeight: "bold", marginLeft: 15}}>Contact</p></Row>
                              <Row><p style={{ color: "black", marginLeft: 15 }}><a style={{ color: "black" }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></p></Row>
                              
                                  <Row><p style={{ color: "#F33B30", fontWeight: "bold", marginLeft: 15 }}>Legal</p></Row>
                                  <Row><p style={{ color: "black", marginLeft: 15, cursor: "pointer" }} onClick={() => handleNavigateLegal("privacy") }>Privacy</p></Row>
                                  <Row><p style={{ color: "black", marginLeft: 15, cursor: "pointer" }} onClick={() => handleNavigateLegal("terms-and-conditions") }>Terms & Conditions</p></Row>
                              
                          
                          </Col>
                         
                          <Col>
                              <Row><p style={{ color: "#F33B30", fontWeight: "bold" }}>Follow Us</p></Row>
                              <Row>
                                  <Row>
                                      <div style={{display: "flex", gap: 10} }>
                                          <a href="https://www.facebook.com/KlaxonAI1" target="_blank" style={{ borderRadius: 30, minHeight: 70, maxHeight: 50, display: "flex", margin: "0 5px 0 5px" }}>
                                              <img style={{ width: 20 }} src={FacebookSVG} className="" />
                                          </a>

                                          <a href="https://www.instagram.com/klaxon_ai/?igsh=OXFhcWJycWxydmk3" target="_blank" style={{ borderRadius: 30, minHeight: 70, maxHeight: 50, display: "flex", margin: "0 5px 0 5px" }}>
                                              <img style={{ width: 20 }} src={InstaSVG} className="" />
                                          </a>

                                          <a href="https://twitter.com/" target="_blank" style={{ borderRadius: 30, minHeight: 70, maxHeight: 50, display: "flex", margin: "0 5px 0 5px" }}>
                                              <img style={{ width: 20 }} src={XSVG} className="" />
                                          </a>

                                          <a href="https://www.tiktok.com/@klaxonai" target="_blank" style={{ borderRadius: 30, minHeight: 70, maxHeight: 50, display: "flex", margin: "0 5px 0 5px" }}>
                                              <img style={{ width: 20 }} src={TiktokSVG} className="" />
                                          </a>

                                          <a href="https://www.linkedin.com/company/klaxonai/" target="_blank" style={{ borderRadius: 30, minHeight: 70, maxHeight: 50, display: "flex", margin: "0 5px 0 5px" }}>
                                              <img style={{ width: 20 }} src={LinkedinSVG} className="" />
                                          </a>
                                      </div>

                                  </Row>
                              </Row>
                              <Row className="pt-3">
                                  <p style={{ color: "#000" }}>&copy; {new Date().getFullYear()} Klaxon AI Ltd</p>
                              </Row>
                          </Col>
                      </Row>
                  </Col>
              </Row>
      </Col>
    </footer>
  );
}

export default FooterLight;
