/*eslint-disable*/
import React from "react";
import { Row, Col } from "reactstrap";
function AdminFooter() {
    return (
        <footer style={{ display: "flex", justifyContent: "center" }} >
            <div style={{ width: "90%", paddingTop: 40, paddingBottom: 10, fontSize: 18 }}>

                <Row>
                    <Col className="text-right">
                        <p>&copy; Copyright {new Date().getFullYear()} KLAXON AI LIMITED (15110517)</p>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}


export default AdminFooter;
